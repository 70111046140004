import { ServerStackIcon } from '@heroicons/react/20/solid';
import {
  AdjustmentsHorizontalIcon,
  ArchiveBoxIcon,
  // ChatBubbleLeftIcon,
  ClockIcon,
  CurrencyDollarIcon,
  HomeIcon,
  ShoppingCartIcon,
  TagIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';

export interface Route {
  name: string;
  to: string;
  code?: string;
  icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  isActive: boolean;
  childrens?: Route[];
}

export const routes: Route[] = [
  {
    name: 'Dashboard',
    to: '/',
    icon: HomeIcon,
    isActive: false,
    code: 'bos.dashboard'
  },
  {
    name: 'User Management',
    to: '/user-management',
    icon: UserGroupIcon,
    isActive: false,
    code: 'bos.user',
    childrens: [
      {
        name: 'User Group',
        to: '/user-management',
        isActive: false,
        code: 'bos.user.group'
      },
      {
        name: 'BOS Account Type',
        to: '/bos-acc-type',
        isActive: false,
        code: 'bos.user.roles'
      },
      {
        name: 'User Admin',
        to: '/user-admin',
        isActive: false,
        code: 'bos.user.admin'
      }
    ]
  },
  {
    name: 'Frontstore Management',
    to: '/frontstore-management',
    icon: ArchiveBoxIcon,
    isActive: false,
    code: 'bos.frontstore',
    childrens: [
      {
        name: 'Page Builder',
        to: '/frontstore-management/page-builder',
        isActive: false,
        code: 'bos.frontstore.pagebuilder'
      },
      {
        name: 'Banner',
        to: '/frontstore-management/banner',
        isActive: false,
        code: 'bos.frontstore.banner'
      },
      {
        name: 'Product Highlight',
        to: '/product-highlight',
        isActive: false,
        code: 'bos.fronstore.producthighlight'
      }
    ]
  },
  {
    name: 'Customer Management',
    to: '/customer-management',
    icon: UserGroupIcon,
    isActive: false,
    code: 'bos.customer'
  },
  {
    name: 'Category Management',
    to: '/category',
    icon: AdjustmentsHorizontalIcon,
    isActive: false,
    code: 'bos.category',
    childrens: [
      { name: 'Category', to: '/category', isActive: false, code: 'bos.category.list' },
      {
        name: 'Specification',
        to: '/specification',
        isActive: false,
        code: 'bos.category.specification'
      },
      {
        name: 'Variant',
        to: '/variant',
        isActive: false,
        code: 'bos.category.variant'
      },
      {
        name: 'Attribute Set',
        to: '/attribute-set',
        isActive: false,
        code: 'bos.category.attribute'
      }
    ]
  },
  {
    name: 'Product Management',
    to: '/product',
    icon: ShoppingCartIcon,
    isActive: false,
    code: 'bos.product',
    childrens: [
      {
        name: 'Product',
        to: '/product',
        isActive: false,
        code: 'bos.product.list'
      }
    ]
  },
  {
    name: 'Promotion Management',
    to: '/promo-tagging"',
    icon: TagIcon,
    isActive: false,
    code: 'bos.promotion',
    childrens: [
      {
        name: 'Promo Tagging',
        to: '/promo-tagging',
        isActive: false,
        code: 'bos.promotion.taging'
      },
      {
        name: 'Voucher',
        to: '/voucher',
        isActive: false,
        code: 'bos.promotion.voucher'
      },
      {
        name: 'Promo Ongkir',
        to: '/ongkir',
        isActive: false,
        code: 'bos.promotion.ongkir'
      },
      {
        name: 'Promo Page',
        to: '/promo-page',
        isActive: false,
        code: 'bos.promotion.promopage'
      },
      {
        name: 'Shop+',
        to: '/shop-plus',
        isActive: false,
        code: 'bos.promotion.shopplus'
      }
    ]
  },
  {
    name: 'Flash Sale Management',
    to: '/flash-sale',
    icon: ClockIcon,
    isActive: false,
    code: 'bos.flashsale',
    childrens: [
      {
        name: 'Flash Sale',
        to: '/flash-sale',
        isActive: false,
        code: 'bos.flashsale.list'
      },
      {
        name: 'Master Slot',
        to: '/master-slot',
        isActive: false,
        code: 'bos.flashsale.masterslot'
      }
    ]
  },
  {
    name: 'Order Management',
    to: '/order-management',
    icon: CurrencyDollarIcon,
    isActive: false,
    code: 'bos.order'
  },
  {
    name: 'Audit Trail',
    to: '/audit-trail',
    icon: ServerStackIcon,
    isActive: false,
    code: 'bos.audit'
  },
  {
    name: 'Import History',
    to: '/import-history/list',
    icon: AdjustmentsHorizontalIcon,
    isActive: false,
    code: 'bos.importhistory'
  }
  // {
  //   name: 'Customer Support',
  //   to: '/customer-support',
  //   icon: ChatBubbleLeftIcon,
  //   isActive: false
  // }
];

interface IsChildActiveResult {
  route: Route;
  isActive: boolean;
}

export function isActive(route: Route, path: string): IsChildActiveResult {
  route.isActive = route.to == path;

  if (route.childrens && route.childrens.length > 0) {
    let isChildActive = false;
    route.childrens = route.childrens.map((e) => {
      const isActiveResult = isActive(e, path);
      if (isActiveResult.isActive && !isChildActive) {
        isChildActive = true;
      }
      return isActiveResult.route;
    });
    route.isActive = isChildActive;
  }

  return {
    route,
    isActive: route.isActive
  };
}

export const setIsActive = (routeCollection: Route[], path: string): Route[] => {
  return routeCollection.map((rc) => isActive(rc, path).route);
};
