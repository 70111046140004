import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { IListFlashSale, IPeriode } from './type';
import { IFilterFlashsale, useDataFlashSale, useDeleteFlashSale, useFilterFlashSale } from '@/services/rest/flashSale';
import dayjs from 'dayjs';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';

export declare interface Flashsale {
  id: string;
  product_id: string;
  weight: string;
  created_at: string;
  product_variant_id: string;
  updated_at: string;
  flash_sale_stock: string;
  master_slot_id: string;
  flash_sale_stock_salable: string;
  periode: string;
}
interface IOptions {
  label: string;
  items: Array<IItemsOption>;
}
interface IItemsOption {
  label: string;
  name: string;
  value: number | boolean;
}

export const useCustom = () => {
  const initialFilter: IFilterFlashsale = {
    id_from: null,
    id_to: null,
    name: '',
    created_by: '',
    updated_by: '',
    created_at: [],
    updated_at: [],
    status: ''
  };

  const [flashsale, setFlashsale] = useState<IListFlashSale[]>([]);
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { mutate: mutateFlashSale, isSuccess: successDeleteFlashSale, isError: errorDeleteFlashSale } = useDeleteFlashSale();
  const { data: optionName } = useFilterFlashSale();
  const flashName = useMemo(()=> {
    if (optionName?.data) {
      return optionName.data.map((item)=> ({
        label: item.name,
        name: 'name',
        value: item.id
      }));
    }
    return [];
  }, [optionName]);
  const options: IOptions[] = [
    {
      label: 'Name',
      items: flashName
    },
    {
      label: 'Status',
      items: [
        { label: 'Enabled', name: 'status', value: true },
        { label: 'Disabled', name: 'status', value: false }
      ]
    }
  ];
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.flashSale.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.flashSale != '' ? JSON.parse(String(lastFilterPage.flashSale)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.flashSale != '' ? JSON.parse(String(lastFilterPage.flashSale)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1000);

  const { data: dataFlashSale, refetch } = useDataFlashSale(paginator.range, debounceSearch, filters);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const dataMemoFlashSale = useMemo(()=>{
    const today = dayjs(new Date()).format('YYYY-MM-DD');
    const tempFlashSale =
    Array.isArray(dataFlashSale?.data) ?
      dataFlashSale?.data.map((item) => {
        let isEditable = false;
        if (item.periode.isRange) {
          const [, endDate] = item.periode.date;
          isEditable = today <= endDate;
        } else if (item.periode.date.length > 1) {
          isEditable = item.periode.date.reduce((current, next) => {
            if (typeof current == 'boolean') return Boolean(current) || Boolean(today <= next);
            return Boolean(today <= current) || Boolean(today <= next);
          });
        } else isEditable = today <= item.periode.date[0];
        return {
          id: item.id,
          name: item.name,
          created_by: item.created_by,
          updated_by: item.updated_by,
          created_at: item.created_at,
          updated_at: item.updated_at,
          status: (item.periode as IPeriode)?.isEnable === true ? 'Enable' : 'Disabled',
          perioder: item.periode,
          isEditable
        };
      }): [];
    return tempFlashSale;
  }, [dataFlashSale]);

  useEffect(() => {
    setIsLoading(false);
  }, [setIsLoading, dataMemoFlashSale]);

  const confirmDelete = useCallback((id: string)=>{
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept() {
        const params:string[] = [];
        if (flashsale?.length > 0) {
          flashsale?.forEach((item)=>{
            params.push(`${item?.id}`);
          });
        } else {
          params.push(id);
        }
        mutateFlashSale(params);
      },
      reject
    });
  }, [flashsale]);

  const reject = () => {
    toast.current?.show({ severity: 'warn', summary: 'Rejected', detail: 'Delete canceled', life: 3000 });
  };

  const showError = (msg:string)=>{
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: msg
    });
  };

  const deleteFlashSale = useCallback(async (ids: string[]) => {
    const { data: activeFS } = await supabase.from(TABLE.FLASHSALE).select('id').in('id', ids).eq('periode->>isEnable', true);
    if (activeFS && activeFS.length > 0) {
      showError('Flash Sale Masih Berjalan');
      return;
    }
    const { error } = await supabase.from(TABLE.FLASHSALE)
      .update(
        {
          deleted_at: dayjs().format('YYYY-MM-DD HH:mm:ssZ')
        }
      ).in('id', ids);
    const { error: productFs } = await supabase.from(TABLE.FLASHSALE)
      .update(
        {
          deleted_at: dayjs().format('YYYY-MM-DD HH:mm:ssZ')
        }
      ).in('parent_id', ids);
    if (productFs) {
      showError('Gagal Menghapus Flash Sale');
      return;
    }
    if (error) {
      // detected RLS error
      showError('Gagal Menghapus Flash Sale');
      setIsLoading(false);
    } else {
      showSuccessToast('Berhasil Menghapus Flash Sale');
      setIsLoading(false);
      refetch();
    }
  }, []);

  const showSuccessToast = (msg:string) =>{
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: msg,
      life: 3000
    });
  };

  useEffect(()=>{
    if (successDeleteFlashSale) {
      showSuccessToast('Berhasil menghapus data');
      refetch();
    }
  }, [successDeleteFlashSale]);

  useEffect(()=>{
    if (errorDeleteFlashSale) {
      showError('Gagal menghapus data');
    }
  }, [errorDeleteFlashSale]);

  const onDeleteFlashSale = (id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept() {
        if (flashsale.length > 0) {
          deleteFlashSale(flashsale.map((fs) => `${fs.id}`));
        } else {
          deleteFlashSale([id]);
        }
      }
    });
  };

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      flashSale: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const totalRecords = useMemo(() => {
    return dataFlashSale?.count ? dataFlashSale.count : 0;
  }, [dataFlashSale]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);


  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter?.created_at && filter.created_at[0]) {
      let filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')}`;
      if (filter.created_at.length > 1 && filter.created_at[1] !== null) {
        filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.created_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Created At', 'created_at', filterDate));
    }

    if (filter?.updated_at && filter.updated_at[0]) {
      let filterDate = `${dayjs(filter.updated_at[0]).format('YYYY-MM-DD')}`;
      if (filter.updated_at.length > 1 && filter.updated_at[1] !== null) {
        filterDate = `${dayjs(filter.updated_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.updated_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Updated At', 'updated_at', filterDate));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.created_by !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Created By', 'created_by', filter.created_by));
    }

    if (filter.updated_by !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Updated By', 'updated_by', filter.updated_by));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      flashSale: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      flashSale: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      flashsale,
      isLoading,
      toast,
      options,
      filters,
      dataMemoFlashSale,
      perPage,
      totalPages,
      totalRecords,
      paginator,
      filterHistory,
      itemFilters,
      search
    },
    methods: {
      onDeleteFlashSale,
      handleFilter,
      confirmDelete,
      setFlashsale,
      handleClickNext,
      handleClickPrev,
      handleChangeDropdownPage,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleClickSubmitFilter,
      handleSearch,
      handleClearFilter,
      handleDeleteFilterHistory
    }
  };
};
