import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowClickEvent, DataTableSortEvent } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import IconPencil from '../../../assets/IconPencil';
import IconTrash from '@/assets/IconTrash';
import IconShow from '@/assets/IconShow';
import Pagination, { IPagination } from '../Pagination';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import FilterHistory, { IFilterHistoryItems } from '../FilterHistory';
import { InputNumber } from 'primereact/inputnumber';
import { IListProduct, RCTIProduct } from '@/views/ShopPlus/type';

type ITable = {
  value: [];
  filter?: string;
  headerTable: { field: string; header: string, body?: (e) => JSX.Element, sortable?: boolean }[];
  onSearch?: (e) => void;
  onDeleted?: (id) => void;
  onEditable?: (id) => void;
  onSave?: (id) => void;
  isWithIcon?: boolean;
  isWithCheckBox?: boolean;
  customId?: string;
  selection?: [];
  onSelectionChange?: (e) => void;
  onhandleDiscountChange?: (value: number, id: number) => void;
  onhandleProductUrlChange?: (value: string, id: number) => void;
  isLoadingTable?: boolean;
  isDetailAction?: boolean;
  isEditAction?: boolean;
  isDeleteAction?: boolean;
  isDuplicateAction?: boolean;
  onDuplicate?: (data: Object) => void;
  isProductHighlight?: boolean;
  isCustomAction?: boolean;
  paginationOptions?: IPagination;
  onSort?: (e: DataTableSortEvent) => void;
  rows?: number;
  filterDelay?: number;
  onRowClick?: (e: DataTableRowClickEvent) => void;
  filterContent?: JSX.Element;
  filterHistory?: IFilterHistoryItems[];
  handleClearFilter?: () => void;
  handleDeleteFilterHistory: (name: string, value: string[]) => void;
  onSubmitFilter?: () => void;
  isWithButton?: boolean;
  countStatusReview?: number;
  createAdd?: boolean;
  isCustomeInput?: boolean;
  isCustomShopPlus?: boolean;
  isUTMInput?: boolean;
  filterWaiting?: boolean;
  searchHide?: boolean;
  countDiscountPrice?: boolean;
  isDiscountPriceEdit?: boolean;
  editDiscountPrice?: boolean;
  editProductId?: number;
  onCloseState?: () => void;
  editableData?: RCTIProduct[];
  formUtm?: boolean;
  isCustomActionShopPlus?: boolean;
};


const Table: FC<ITable> = ({
  value,
  filter,
  headerTable,
  onSearch,
  onDeleted=()=>{},
  onEditable=()=>{},
  onSave=()=>{},
  isWithIcon,
  isWithCheckBox,
  customId = 'id',
  selection,
  onSelectionChange,
  onhandleDiscountChange,
  onhandleProductUrlChange,
  onDuplicate = () => { },
  isLoadingTable,
  isDetailAction = true,
  isEditAction = true,
  isDeleteAction = true,
  isDuplicateAction,
  isProductHighlight,
  isCustomAction,
  paginationOptions,
  onSort,
  rows = 10,
  filterDelay = 300,
  onRowClick,
  filterContent,
  filterHistory,
  handleClearFilter,
  handleDeleteFilterHistory,
  onSubmitFilter,
  isWithButton,
  countStatusReview,
  createAdd,
  isCustomeInput,
  isUTMInput,
  filterWaiting,
  searchHide,
  countDiscountPrice,
  isCustomShopPlus,
  isDiscountPriceEdit,
  editDiscountPrice,
  editProductId,
  onCloseState,
  editableData,
  formUtm,
  isCustomActionShopPlus
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [isOpenDialogAddProduct, setIsOpenDialogAddProduct] = useState<boolean>(false);

  const deleted = useCallback(
    (id) => {
      onDeleted(id);
    },
    [onDeleted]
  );

  const duplicate = useCallback(
    (data: Object) => {
      onDuplicate(data);
    },
    [onDuplicate]
  );

  const onClickBtnAddProduct = useCallback(() => {
    setIsOpenDialogAddProduct(true);
  }, []);

  const onCloseClickBtnAddProduct = useCallback(() => {
    if (onCloseState) {
      onCloseState();
    }
    setIsOpenDialogAddProduct(false);
  }, [onCloseState]);

  const onClickBtnFilter = useCallback(() => {
    setIsOpenDialog(true);
  }, []);

  const onClickSubmitFilter = useCallback(() => {
    if (onSubmitFilter) {
      onSubmitFilter();
    }
    setIsOpenDialog(false);
    setIsOpenDialogAddProduct(false);
  }, [onSubmitFilter]);

  const formatRupiahWithoutSymbol = (number: number) => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  };


  const footerContent = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={() => setIsOpenDialog(false)} size="large" />
      <Button label="Terapkan" severity="info" onClick={onClickSubmitFilter} size="large" />
    </div>
  );

  const footerContentAddProduct = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={onCloseClickBtnAddProduct} size="large" />
      <Button label="Konfirmasi" severity="danger" disabled={countDiscountPrice} onClick={onClickSubmitFilter} size="large" />
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 my-4">
        {!isCustomeInput && (
          <div className="flex justify-end gap-2">
            {createAdd === true ? (
              <Button
                className="gap-4"
                icon="pi pi-plus"
                onClick={onClickBtnAddProduct}
                label="Tambah"
                severity="danger"
              />
            ) : createAdd === false ? (
              <div />
            ) : filterWaiting ? (
              <Button
                className="gap-4"
                onClick={onClickSubmitFilter}
                label="Menunggu Approvel"
                severity="secondary"
                text
                raised
                disabled={countStatusReview === 0}
              >
                <div
                  className={`p-button p-component ${
                    (countStatusReview ?? 0) <= 0 ? 'p-button-secondary' : 'p-button-danger'
                  }`}
                >
                  <p>{countStatusReview}</p>
                </div>
              </Button>
            ) : (
              <Button
                className="gap-4"
                icon="pi pi-sliders-h"
                onClick={onClickBtnFilter}
                label="Filter"
                severity="secondary"
                text
                raised
              />
            )}

            {(isWithButton && (
              <div className="flex gap-2">
                <Link to="/product-highlight/create">
                  <Button label="Add Product" className="w-[180px] h-fit" icon="pi pi-plus" severity="danger" />
                </Link>
                <Link to="/product-highlight/create/bulk">
                  <Button label="Upload Product" className="w-[180px] h-fit" severity="danger"/>
                </Link>
              </div>
            ))}
          </div>
        )}

        {filterHistory && filterHistory.length > 0 ?
          <FilterHistory
            filterHistory={filterHistory}
            handleClearFilter={handleClearFilter}
            handleDeleteFilterHistory={handleDeleteFilterHistory}
            show={filterHistory?.length > 0}
          /> :
          <Divider className="p-divider-solid " />
        }

        {!searchHide ? (
          !isCustomeInput ? (
            <div className="flex justify-between items-center">
              <div id="search" className="p-input-icon-right w-[20%]">
                <InputText
                  className="w-full"
                  placeholder="Cari Disini"
                  type="search"
                  onChange={onSearch}
                  keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
                  value={filter}
                />
                <i className="pi pi-search" />
              </div>
              <div className="flex items-center justify-end gap-6 w-full">
                <h4 className="font-bold justify-self-end"> {rows} data ditemukan</h4>
                <Pagination
                  perPage={paginationOptions?.perPage}
                  totalPages={paginationOptions?.totalPages}
                  currentPage={paginationOptions?.currentPage}
                  disabledDropdown={paginationOptions?.disabledDropdown}
                  onClickPrev={paginationOptions?.onClickPrev}
                  onClickNext={paginationOptions?.onClickNext}
                  onChangeDropdownPage={paginationOptions?.onChangeDropdownPage}
                  onJumptoPage={paginationOptions?.onJumptoPage}
                  onChangeJumptoPage={paginationOptions?.onChangeJumptoPage}
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-between items-center">
              <div id="search" className="p-input-icon-right w-[50%]">
                <InputText
                  className="w-full"
                  placeholder="SKU / Product ID / Name"
                  type="search"
                  onChange={onSearch}
                  keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
                  value={filter}
                />
                <i className="pi pi-search" />
              </div>
            </div>
          )
        ) : (
          <div className="flex justify-between items-center">
            <div id="search" className="p-input-icon-right w-[20%]" />
            <div className="flex items-center justify-end gap-6 w-full">
              <h4 className="font-bold justify-self-end"> {rows} data ditemukan</h4>
              <Pagination
                perPage={paginationOptions?.perPage}
                totalPages={paginationOptions?.totalPages}
                currentPage={paginationOptions?.currentPage}
                disabledDropdown={paginationOptions?.disabledDropdown}
                onClickPrev={paginationOptions?.onClickPrev}
                onClickNext={paginationOptions?.onClickNext}
                onChangeDropdownPage={paginationOptions?.onChangeDropdownPage}
                onJumptoPage={paginationOptions?.onJumptoPage}
                onChangeJumptoPage={paginationOptions?.onChangeJumptoPage}
              />
            </div>
          </div>
        )}

      </div>
      <div className="card border">
        <DataTable
          value={value}
          rows={rows}
          scrollable
          scrollHeight="34rem"
          dataKey={customId}
          showGridlines
          removableSort
          selectionPageOnly
          filterDelay={filterDelay}
          emptyMessage="No Data Found"
          tableStyle={{ minWidth: '12rem' }}
          selectionMode="checkbox"
          onSort={onSort}
          selection={selection}
          onSelectionChange={onSelectionChange}
          loading={isLoadingTable}
          onRowClick={onRowClick}
        >
          {isWithCheckBox && <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />}
          {headerTable.map((item, index) => {
            return item.body !== null ?
              (
                <Column key={`${item.header} ${index}`} alignHeader="center" body={item.body} sortable={item.sortable === undefined} align="center" field={item.field} header={item.header} />
              ) :
              (
                <Column key={`${item.header} ${index}`} alignHeader="center" align="center" field={item.field} header={item.header} />
              );
          })}
          {isWithIcon && (
            <Column
              header="icon"
              alignHeader="center"
              align="center"
              body={(rowData) =>
                rowData.images ? (
                  <div>
                    <img src={rowData.images.data.image} width={70} alt="" />
                  </div>
                ) : null
              }
            />
          )}
          {isCustomeInput && (
            <Column
              header="Discount Price"
              alignHeader="center"
              align="center"
              body={(rowData: IListProduct) =>
                <InputNumber
                  required={!selection?.some((row: IListProduct) => row.id === rowData.id)}
                  disabled={!selection?.some((row: IListProduct) => row.id === rowData.id)}
                  onChange={(e) => onhandleDiscountChange && onhandleDiscountChange(e.value ?? 0, rowData.id)}
                  value={rowData.discount_price || 0}
                  name="discount_price"
                />
              }
            />
          )}
          {isDiscountPriceEdit && (
            <Column
              header="Discount Price"
              alignHeader="center"
              align="center"
              body={(rowData: RCTIProduct) =>
                editDiscountPrice && rowData.product_id === editProductId ? (
                  <InputNumber
                    onChange={(e) => onhandleDiscountChange && onhandleDiscountChange(e.value ?? 0, rowData.product_id)}
                    value={rowData.discount_price || 0}
                    name="product_url"
                  />
                ) : (
                  <p>{formatRupiahWithoutSymbol(rowData.discount_price)}</p>
                )
              }
            />
          )}
          {isUTMInput && (
            <Column
              header="UTM URL"
              alignHeader="center"
              align="center"
              body={(rowData: RCTIProduct) => {
                const currentProduct = editableData && editableData.find((item) => item.product_id === rowData.product_id);

                return formUtm ? (
                  <InputText
                    key={rowData.product_id}
                    onChange={(e) => onhandleProductUrlChange && onhandleProductUrlChange(e.target.value ?? '', rowData.product_id)}
                    value={currentProduct ? currentProduct.product_url : rowData.product_url || ''}
                    name="product_url"
                  />
                ) : (
                  <p>{rowData.product_url}</p>
                );
              }}
            />
          )}
          {isCustomShopPlus && (
            <Column
              header="Action"
              alignHeader="center"
              align="center"
              body={(rowData: RCTIProduct) => (
                <div className="flex justify-center gap-2">
                  {editDiscountPrice && rowData.product_id === editProductId ? (
                    <Button
                      icon="pi pi-save"
                      outlined
                      rounded
                      severity="secondary"
                      onClick={() => onSave(rowData.product_id)}
                    />
                  ) : (
                    <Button
                      icon={IconPencil}
                      outlined
                      rounded
                      severity="secondary"
                      onClick={() => onEditable(rowData.product_id)}
                    />
                  )}
                  <Button
                    icon={IconTrash}
                    outlined
                    rounded
                    severity="danger"
                    onClick={() => deleted(rowData.product_id)} />
                </div>
              )}
            />
          )}
          {isCustomActionShopPlus && (
            <Column
              header="Action"
              alignHeader="center"
              align="center"
              body={(rowData) => (
                <div className="flex justify-center gap-2">
                  {(typeof rowData?.isViewable === 'undefined' || rowData?.isViewable) && (
                    <Link to={isProductHighlight ? `${rowData.id}?section=${rowData.section}` : `${rowData.id}`}>
                      <Button
                        icon={IconShow}
                        outlined
                        rounded
                        severity="secondary"
                      />
                    </Link>
                  )}
                  {(typeof rowData?.isEditable === 'undefined' || rowData?.isEditable) && (
                    <Link to={isProductHighlight ? `edit/${rowData.id}?section=${rowData.section}` : `edit/${rowData.id}`}>
                      <Button
                        icon={IconPencil}
                        outlined
                        rounded
                        severity="secondary"
                      />
                    </Link>
                  )}
                  <Button
                    icon={IconTrash}
                    outlined
                    rounded
                    disabled={typeof rowData?.isDeleteable === 'undefined' || rowData?.isDeleteable}
                    severity="danger"
                    onClick={(e) => deleted(isProductHighlight ? rowData : rowData.id)} />
                </div>
              )}
            />
          )}
          {!isCustomAction && (
            <Column
              header="Action"
              alignHeader="center"
              align="center"
              body={(rowData) => (
                <div className="flex justify-center gap-2">
                  {isDetailAction && (typeof rowData?.isViewable === 'undefined' || rowData?.isViewable) && (
                    <Link to={isProductHighlight ? `${rowData.id}?section=${rowData.section}` : `${rowData.id}`}>
                      <Button
                        icon={IconShow}
                        outlined
                        rounded
                        severity="secondary"
                      />
                    </Link>
                  )}
                  {isEditAction && (typeof rowData?.isEditable === 'undefined' || rowData?.isEditable) && (
                    <Link to={isProductHighlight ? `edit/${rowData.id}?section=${rowData.section}` : `edit/${rowData.id}`}>
                      <Button
                        icon={IconPencil}
                        outlined
                        rounded
                        severity="secondary"
                      />
                    </Link>
                  )}
                  {isDeleteAction && (typeof rowData?.isEditable === 'undefined' || rowData?.isEditable) && (
                    <Button
                      icon={IconTrash}
                      outlined
                      rounded
                      severity="danger"
                      onClick={(e) => deleted(isProductHighlight ? rowData : rowData.id)} />
                  )}
                  {isDuplicateAction && (
                    <Button icon="pi pi-copy" outlined rounded onClick={() => duplicate(rowData)} />
                  )}
                </div>
              )}
            />
          )}
        </DataTable>
      </div>
      <Dialog onHide={() => setIsOpenDialog(false)} visible={isOpenDialog} header="Semua Filter" footer={footerContent}>
        {filterContent}
      </Dialog>
      <Dialog style={{ width: '74%', height: '74%' }} onHide={onCloseClickBtnAddProduct} visible={isOpenDialogAddProduct} header="Tambah Produk" footer={footerContentAddProduct}>
        {filterContent}
      </Dialog>
    </div>
  );
};


export default Table;
