import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { useQuery } from '@tanstack/react-query';

interface IProduct {
  id: number;
  name?: string;
  sku: string;
  merchants?: IMerchants;
  product_variants?: IProductVariants;
  images: string;
  is_active: boolean;
  status: string;
  discount_price?: number;
}

interface IMerchants {
  name?: string;
}

interface IProductVariants {
  price: number;
  is_primary_variant: boolean;
  sku_id: string;
}

interface CatalogueRCTI {
  id: number;
  name: string;
  merchant_id?: string;
  descriptions: string;
  periode: string;
  status: string;
  status_notes: string;
  url: string;
  created_at: Date;
  updated_at: Date;
  approved_by?: string;
  source: string;
  merchant_name: string;
  voucher: string;
}

interface RCTIProduct {
  id: number;
  catalogue_rcti_id: number;
  sku: string;
  product_id: number;
  product_name: string;
  product_url: string;
  price: number;
  discount?: number;
  discount_price: number;
  status: boolean;
  image_url: string;
  merchant_name: string;
}

export interface IFilterCatalogue {
  status: string ;
};

const getAllProduct = async (search?: string) => {
  const query = supabase
    .from(TABLE.PRODUCT)
    .select(`
      id, name, status, is_active, images, sku,
      merchants!inner(name),
      product_variants!inner(id, price, sku_id, is_primary_variant)
    `, { count: 'exact' })
    .eq('status', 'APPROVED')
    .eq('is_active', true)
    .eq('product_variants.is_primary_variant', true)
    .limit(10);

  if (search) {
    const numericSearch = Number(search);

    if (!isNaN(numericSearch)) {
      query.eq('id', numericSearch);
      query.order('id', { ascending: true });
    } else {
      query.or(`sku.ilike.%${search}%,name.ilike.%${search}%`);
      query.order('id', { ascending: true });
    }
  }

  const { data, error } = await query;

  if (error) {
    return null;
  }

  const filteredData = data?.map((product) => {
    const primaryVariant = product.product_variants?.find(
      (variant: IProductVariants) => variant.is_primary_variant === true
    );
    return {
      ...product,
      id: product.id,
      name: product.name,
      sku: product.sku,
      status: product.status,
      is_active: product.is_active,
      images: product.images.length > 0 ? product.images[0] : null,
      product_variants: primaryVariant ? primaryVariant : null
    };
  });

  return filteredData as IProduct[];
};

const getAllProductCatalogue = async (catalogueId: number, range: IPagination = { start: 0, end: 10 }) => {
  const defaultResponse = {
    data: [],
    error: null,
    count: 0,
    status: 200,
    statusText: 'OK'
  };

  if (!catalogueId) {
    return defaultResponse;
  }

  const query = supabase
    .from(TABLE.PRODUCT_RCTI)
    .select('*', { count: 'exact' })
    .order('id', { ascending: true })
    .eq('catalogue_rcti_id', catalogueId);

  const { data, error, count, status, statusText } = await query;

  if (error) {
    return {
      ...defaultResponse,
      error,
      status,
      statusText
    };
  }

  const filterData: RCTIProduct[] = data.map((product) => {
    return {
      ...product,
      id: product.id,
      catalogue_rcti_id: product.catalogue_rcti_id,
      sku: product.sku,
      product_id: product.product_id,
      product_name: product.product_name,
      product_url: product.product_url,
      price: product.price,
      discount: product.discount,
      discount_price: product.discount_price,
      status: product.status,
      images_url: product.image_url,
      merchant_name: product.merchant_name
    };
  });

  return {
    data: filterData || [],
    error: null,
    count: count || 0,
    status,
    statusText
  };
};

const getAllCatalogue = async (filter?: IFilterCatalogue, search?: string, range: IPagination = { start: 0, end: 10 }) => {
  const defaultResponse = {
    data: [],
    error: null,
    count: 0,
    status: 200,
    statusText: 'OK'
  };

  const query = supabase
    .from(TABLE.CATALOGUE_RCTI)
    .select('*', { count: 'exact' })
    .range(range.start, range.end)
    .order('id', { ascending: true });

  if (filter?.status != '') {
    query.eq('status', filter?.status);
  }

  if (search) {
    const numericSearch = Number(search);

    if (!isNaN(numericSearch)) {
      query.eq('id', numericSearch);
    } else {
      query.or(`name.ilike.%${search}%, merchant_name.ilike.%${search}%`);
    }
  }

  query.eq('is_deleted', false);

  const { data, error, count, status, statusText } = await query;

  if (error) {
    return {
      ...defaultResponse,
      error,
      status,
      statusText
    };
  }

  const filteredData: CatalogueRCTI[] = data?.map((product) => {
    return {
      ...product,
      id: product.id,
      name: product.name,
      merchant_id: product.merchant_id,
      descriptions: product.descriptions,
      periode: product.periode,
      status: product.status,
      status_notes: product.status_notes,
      url: product.url,
      created_at: product.created_at,
      updated_at: product.updated_at,
      approved_by: product.approved_by,
      source: product.source,
      merchant_name: product.merchant_name,
      voucher: product.voucher
    };
  });

  return {
    data: filteredData || [],
    error: null,
    count: count || 0,
    status,
    statusText
  };
};

const countReviewCatalogue = async () => {
  const { count } = await supabase
    .from(TABLE.CATALOGUE_RCTI)
    .select('id', { count: 'exact' })
    .eq('status', 'review')
    .eq('is_deleted', false);

  return count;
};

const lastIdCatalogue = async () => {
  const { data } = await supabase
    .from(TABLE.CATALOGUE_RCTI)
    .select('id')
    .order('id', { ascending: false })
    .limit(1);

  return data;
};

export const deleteCatalogue = async (id: string) => {
  return await supabase
    .from(TABLE.CATALOGUE_RCTI)
    .update({ is_deleted: true }, { count: 'exact' })
    .eq('id', id);
};

export const deleteProductCatalogue = async (id: string) => {
  return await supabase
    .from(TABLE.PRODUCT_RCTI)
    .update({ is_deleted: true }, { count: 'exact' })
    .eq('catalogue_rcti_id', id);
};

export const useAllCatalogue = (filter?: IFilterCatalogue, search?: string, range?: IPagination) =>
  useQuery({
    queryKey: ['catalogue_rcti', filter, search, range],
    queryFn: () => getAllCatalogue(filter, search, range),
    refetchOnWindowFocus: false
  });

export const useAllProductCatalogue = (catalogueId: number) =>
  useQuery({
    queryKey: ['rcti_products', catalogueId],
    queryFn: () => getAllProductCatalogue(catalogueId),
    refetchOnWindowFocus: false
  });

export const totalAllCatalogue = () =>
  useQuery({
    queryKey: ['count_review_catalogue'],
    queryFn: () => countReviewCatalogue(),
    refetchOnWindowFocus: false
  });

export const useIdCatalogue = () =>
  useQuery({
    queryKey: ['id'],
    queryFn: () => lastIdCatalogue(),
    refetchOnWindowFocus: false
  });

export const useAllProduct = (search?: string) =>
  useQuery({
    queryKey: ['products', search],
    queryFn: () => getAllProduct(search),
    refetchOnWindowFocus: false
  });
