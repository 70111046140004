import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import useCustom, { CatalogueRcti } from './hooks';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Table from '@/components/base/DataTable/DataTable';
import { usePermissionStore } from '@/store/usePermissions';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { IPagination } from '@/components/base/Pagination';

const ShopPlus = () => {
  const { data, method } = useCustom();
  const { canCreate, canDelete, canView, canEdit } = usePermissionStore();
  const idTemplate = (catalogue: CatalogueRcti) => {
    return (
      <div>
        <span className="font-bold text-[#EF4444] text-lg" onClick={() => method.detailCatalogue(catalogue.id)}>{catalogue.id}</span>
      </div>
    );
  };
  const nameTemplate = (catalogue: CatalogueRcti) => {
    return (
      <div className="flex">
        <span className="ellipsis-text">{capitalize(catalogue.name)}</span>
      </div>
    );
  };
  const merchantTemplate = (catalogue: CatalogueRcti) => {
    return (
      <div className="flex">
        <span className="ellipsis-text">{capitalize(catalogue.merchant_name || '')}</span>
      </div>
    );
  };
  const urlTemplate = (catalogue: CatalogueRcti) => {
    const url = catalogue.url ?? '';
    return (
      <div>
        { catalogue.status == 'approved' && (
          <div className="flex gap-2">
            <span className="ellipsis">{url}</span>
            {url && (
              <Button
                severity="danger"
                style={{ width: '30px', height: '30px' }}
                icon="pi pi-copy"
                onClick={() => method.confirmCopy(url)}
              />
            )}
          </div>
        )}
      </div>
    );
  };
  const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };
  const statusTemplate = (catalogue: CatalogueRcti) => {
    let textColor;
    switch (catalogue.status) {
    case 'review':
      textColor = '#E6C530';
      break;
    case 'approved':
      textColor = '#2E7D32';
      break;
    default:
      textColor = '#EF4444';
    }
    return (
      <div>
        <span className="font-bold" style={{ color: textColor }}>{capitalize(catalogue.status)}</span>
      </div>
    );
  };
  const header = [
    { field: 'id', header: 'Catalogue ID', body: idTemplate },
    { field: 'name', header: 'Catalogue Name', body: nameTemplate },
    { field: 'merchant_name', header: 'Merchant', body: merchantTemplate },
    { field: 'periode', header: 'Periode' },
    { field: 'status', header: 'Status', body: statusTemplate },
    { field: 'url', header: 'URL', body: urlTemplate }
  ];

  const paginationOptions: IPagination = {
    perPage: data.perPage,
    totalPages: data.totalPages,
    currentPage: data.paginator.currentPage,
    disabledDropdown: data.totalRecords === 0,
    onChangeDropdownPage: method.handleChangeDropdownPage,
    onClickNext: method.handleClickNext,
    onClickPrev: method.handleClickPrev,
    onJumptoPage: method.handleJumpToPage,
    onChangeJumptoPage: method.handleChangeJumpTopage
  };

  return (
    <BaseContainer>
      <LoadingOverlay show={data.isLoadingCatalogues} />
      <ConfirmDialog />
      <Toast ref={data.toast} />
      <MainTitle title="Shop + Product Catalogue" labelButton="Create New Catalogue" nav="create" isWithButton={canCreate} />
      <Table
        filter={data.search}
        onSearch={method.handleSearch}
        filterHistory={data.filterHistory}
        isCustomAction
        isCustomActionShopPlus
        headerTable={header}
        value={data.catalogues as []}
        rows={data.totalRecords}
        paginationOptions={paginationOptions}
        isDeleteAction={canDelete}
        isDetailAction={canView}
        isEditAction={canEdit}
        onDeleted={method.confirmDelete}
        handleDeleteFilterHistory={method.handleDeleteFilterHistory}
        handleClearFilter={method.handleClearFilter}
        onSubmitFilter={method.handleClickSubmitFilter}
        countStatusReview={data.totalReviewRecords ?? 0}
        filterWaiting
      />
    </BaseContainer>
  );
};

export default ShopPlus;
