import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import {
  deleteCatalogue,
  deleteProductCatalogue,
  IFilterCatalogue,
  totalAllCatalogue,
  useAllCatalogue,
  useAllProduct,
  useAllProductCatalogue,
  useIdCatalogue
} from '@/services/rest/shopPlus';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { schemaCreateShopPlus } from './validation';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useHistoryStore } from '@/store/useHistoryStore';
import { debounce, uniqBy } from 'lodash';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { confirmDialog } from 'primereact/confirmdialog';
import { useAuthStore } from '@/store/useAuthStore';
import { IListProduct, RCTIProduct } from './type';

export interface CatalogueRcti {
  id: number;
  name: string;
  merchant_id: string;
  descriptions: string;
  periode: Date;
  status: string;
  status_notes?: string;
  url?: string;
  created_at: string;
  updated_at: string;
  approved_by?: string;
  source: string;
  merchant_name: string;
  voucher: string;
}

export interface ProductRcti {
  id: number;
  name: string;
  sku: string;
  merchants: IMerchants;
  product_variants: IProductVariants;
  images: string[];
  is_active?: string;
  status?: string;
  created_at: string;
  updated_at: string;
  approved_by?: string;
  souuce: string;
  merchant_name: string;
  voucher: string;
}

interface IMerchants {
  name?: string;
}

interface IProductVariants {
  price: number;
  is_primary_variant: boolean;
}

interface IFormik{
  name: string;
  merchant_id?: string;
  descriptions?: string;
  periode?: string;
  status: string;
  status_notes?: string;
  url?: string;
  created_at?: string;
  updated_at?: string;
  approved_by?: string;
  source: string;
  merchant_name: string;
  voucher: string;
}

interface IFormCataloguePage {
  name: string;
  merchant_id: string;
  descriptions: string;
  periode: string;
  status: string;
  status_notes?: string;
  url?: string;
  created_at: string;
  updated_at: string;
  approved_by?: string;
  source: string;
  merchant_name: string;
  voucher: string;
};

const useCustom = () => {
  const initialFilter: IFilterCatalogue = {
    status: ''
  };

  const initialFormik:IFormik = {
    name: '',
    merchant_id: '',
    descriptions: '',
    periode: '',
    status: '',
    status_notes: '',
    url: '',
    created_at: '',
    updated_at: '',
    approved_by: '',
    source: '',
    merchant_name: '',
    voucher: ''
  };

  const initialForm = {
    name: '',
    merchant_id: '',
    descriptions: '',
    periode: '',
    status: '',
    status_notes: '',
    url: '',
    created_at: '',
    updated_at: '',
    approved_by: '',
    source: '',
    merchant_name: '',
    voucher: ''
  };

  const [product, setProduct] = useState<IListProduct[]>([]);
  const [selection, setSelection] = useState<IListProduct[] | []>([]);
  const [rctiProduct, setRctiProduct] = useState<RCTIProduct[]>([]);
  const [filteredProduct, setFilteredProduct] = useState<IListProduct[] | []>([]);
  const [foundCatalogueNames, setFoundCatalogueNames] = useState<{ id: string, name: string }[]|null>(null);
  const params = useParams();
  const navigate = useNavigate();
  const [formInput, setFormInput] = useState<IFormCataloguePage>(initialForm);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [perProductPage, setPerProductPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.shopPlus.toString()) ?? 1;
  const currentProductPage = 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const startProduct = currentProductPage != 1 ? (10 * currentProductPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const endProduct = currentProductPage != 1 ? (10 * currentProductPage) - 1 : perProductPage - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [productPaginator, setProductPaginator] = useState({
    currentProductPage,
    range: {
      start: startProduct,
      end: endProduct
    }
  });
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(perProductPage);
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [jumpToProductPage, setJumpToProductPage] = useState<number>(1);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.shopPlus != '' ? JSON.parse(String(lastFilterPage.shopPlus)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.shopPlus != '' ? JSON.parse(String(lastFilterPage.shopPlus)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [searchProduct, debounceSearchProduct, setSearchProduct] = useDebounce('', 1500);
  const [buttonAddProduct, setButtonAddProduct] = useState<boolean>(false);
  const [buttonProductList, setButtonProductList] = useState<boolean>(false);
  const [editDiscountPrice, setEditDiscountPrice] = useState<boolean>(false);
  const [editProductId, setEditProductId] = useState<number>(0);
  const [status, setStatus] = useState<'review' | 'approved' | 'rejected'>('rejected');
  const toast = useRef<Toast>(null);
  const user = useAuthStore(
    (state) => state.user
  );
  const timeNow = new Date();

  const handleEditCatalogue = useCallback( async () => {
    setIsLoading(true);
    const payloadUpdate = {};

    if (status == 'approved') {
      payloadUpdate['url'] = `${import.meta.env.VITE_APP_SHOP_PLUS_URL}/index.html?catalogue=catalogue${params.id}`;
      payloadUpdate['status'] = status;
      payloadUpdate['approved_by'] = user?.email;
      payloadUpdate['status_notes'] = '';
      for (let i = 0; i < rctiProduct.length; i++) {
        // const project = rctiProduct[i];

        // await supabase
        //   .from(TABLE.PRODUCT_RCTI)
        //   .update({ product_url: project.product_url })
        //   .eq('id', project.id);
      }

      const response = await fetch(`${import.meta.env.VITE_APP_SUPABASE_URL}/functions/v1/oss-catalogue-service?catalogue_rcti_id=${params.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const statusCode = response.status;
      if (statusCode === 200) {
        showSuccessToast('Request was successful!');
      } else if (statusCode === 404) {
        showFailedToast('Resource not found!');
      } else {
        showFailedToast('Unexpected status code: ${statusCode}');
      }
    } else if (status == 'rejected' || status == 'review') {
      payloadUpdate['status'] = 'rejected';
      payloadUpdate['status_notes'] = formInput.status_notes;
    }

    const { error } = await supabase.from(TABLE.CATALOGUE_RCTI)
      .update(payloadUpdate)
      .eq('id', params?.id);

    if (error) {
      showFailedToast('Failed Edit Catalogue');
      setIsLoading(false);
      return;
    }

    showSuccessToast('Catalogue successfully edited');
    refecthCatalogue();
  }, [formInput, params, status, rctiProduct]);

  const formik = useFormik({
    initialValues: initialFormik,
    validationSchema: schemaCreateShopPlus,
    onSubmit: ()=>{
      if (params.id) {
        handleEditCatalogue();
      } else {
        handleCreateCatalogue();
      }
    }
  });

  const handleEditClick = (id: number) => {
    setEditDiscountPrice(true);
    setEditProductId(id);
  };

  const handleSaveClick = (id: number) => {
    setEditDiscountPrice(false);
    setEditProductId(id);
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const resetForm = () => {
    setFormInput(initialForm);
  };

  const getDetailPage = useCallback(async () => {
    const { data } = await supabase.from(TABLE.CATALOGUE_RCTI).select('*').eq('id', params.id||params.detailId).single();
    if (data) {
      const page = data as CatalogueRcti;
      formInputValue('id', page.id);
      formInputValue('name', page.name);
      formInputValue('descriptions', page.descriptions);
      formInputValue('periode', new Date(page.periode));
      formInputValue('status', page.status);
      formInputValue('voucher', page.voucher);
      formInputValue('source', page.source);
      formInputValue('status_notes', page.status_notes ?? '');
      const validStatus: 'review' | 'approved' | 'rejected' =
      page.status === 'review' || page.status === 'approved' || page.status === 'rejected' ?
        page.status :
        'review';

      setStatus(validStatus);
    }
  }, []);

  const handleStatusChange = (newStatus: 'review' | 'approved' | 'rejected') => {
    setStatus(newStatus);
  };

  const confirmProductDelete = useCallback((id: number) => {
    setRctiProduct((prevRctiProducts) => prevRctiProducts.filter((item) => item.product_id !== id));
    setSelection((prevSelectionProducts) => prevSelectionProducts.filter((item) => item.id !== id));
    showSuccessProductDelete();
  }, [rctiProduct, selection, product]);

  const confirmDelete = useCallback((id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      acceptClassName: 'p-button-danger',
      accept() {
        onDelete(id);
      }
    });
  }, []);

  const onDelete = async (id: string) => {
    const { error: errorDeleteCatalogue, count: countDeleteCatalogue } = await deleteCatalogue(id);
    const { error: errorDeleteProductCatalogue, count: countDeleteProductCatalogue } = await deleteProductCatalogue(id);
    if (
      errorDeleteCatalogue && errorDeleteProductCatalogue
    ) {
      showFailedToast();
    }
    if (
      countDeleteCatalogue !== 1 && countDeleteProductCatalogue !== 1
    ) {
      showFailedToast('Forbidden');
    } else {
      showSuccessDelete();
    }
    refecthCatalogue();
  };

  const showFailedToast = (message?: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: message || 'Failed Create Catalogue',
      life: 2000
    });
  };

  const showSuccessToast = (msg?:string) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg || 'New Catalogue successfully created',
      life: 2000
    });
    const timeOut = setTimeout(() => {
      resetForm();
      setIsLoading(false);
      navigate('/shop-plus');
      return () => clearTimeout(timeOut);
    }, 2000);
  };

  const showSuccessProductDelete = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: 'Product deleted succesfully',
      life: 3000
    });
  };

  const showSuccessDelete = () => {
    toast.current?.show({
      severity: 'success',
      summary: 'Confirmed',
      detail: 'Catalogue deleted succesfully',
      life: 3000
    });
  };

  const confirmCopy = useCallback((value: string) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        toast.current?.show({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'Value copied to clipboard successfully',
          life: 3000
        });
      })
      .catch((err) => {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to copy value',
          life: 3000
        });
      });
  }, []);

  const detailCatalogue = useCallback((value: number) => {
    navigate(`/shop-plus/edit/${value}`);
  }, []);

  const { data: dataCatalogues, isLoading: isLoadingCatalogues, refetch: refecthCatalogue } = useAllCatalogue(filters, debounceSearch, paginator.range);
  const { data: dataProducts, isLoading: isLoadingProducts } = useAllProduct(debounceSearchProduct);
  const { data: dataProductCatalogues, isLoading: isLoadingProductCatelogues } = useAllProductCatalogue(Number(params.id || params.detailId));

  const dataMemoProductCatalogue = useMemo(() => {
    const hasProductCataloguesData = Array.isArray(dataProductCatalogues?.data) && (dataProductCatalogues?.count ?? 0) > 0;
    const sourceData = hasProductCataloguesData ? (dataProductCatalogues?.data ?? []) : rctiProduct;

    const paginatedData = sourceData.slice(offset, offset + limit);

    const data = paginatedData.map((item) => ({
      // id: item.id,
      catalogue_rcti_id: item.catalogue_rcti_id,
      sku: item.sku,
      product_id: item.product_id,
      product_name: item.product_name,
      product_url: item.product_url,
      price: item.price,
      discount: item.discount,
      discount_price: item.discount_price,
      status: item.status,
      image_url: item.image_url,
      merchant_name: item.merchant_name
    }));

    const count = hasProductCataloguesData ? dataProductCatalogues?.count ?? 0 : rctiProduct.length;

    return { data, count };
  }, [dataProductCatalogues, rctiProduct, offset, limit]);

  useEffect(() => {
    if (dataMemoProductCatalogue?.data?.length && dataMemoProductCatalogue.data.length !== rctiProduct.length) {
      setRctiProduct(dataMemoProductCatalogue.data);
    }
  }, [dataMemoProductCatalogue, rctiProduct]);

  useEffect(() => {
    setProductPaginator({
      currentProductPage: Math.floor(offset / limit) + 1,
      range: {
        start: offset,
        end: offset + limit - 1
      }
    });
  }, [offset, limit]);

  const handlePageChange = (newPage: number) => {
    setOffset((newPage - 1) * limit);
  };

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
    setOffset(0);
  };

  const totalRecords = useMemo(() => {
    return dataCatalogues?.count ? dataCatalogues.count : 0;
  }, [dataCatalogues]);

  const totalProductRecords = useMemo(() => {
    return dataMemoProductCatalogue?.count ? dataMemoProductCatalogue.count : 0;
  }, [dataMemoProductCatalogue]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const totalProductPages = useMemo(() => {
    return Math.ceil(totalProductRecords / perProductPage);
  }, [totalProductRecords, perProductPage]);

  const catalogues = useMemo(() => {
    if (!dataCatalogues || !Array.isArray(dataCatalogues.data)) return [];

    return dataCatalogues.data.map((catalogue) => {
      return {
        ...catalogue,
        isViewable: catalogue.status === 'approved' || catalogue.status === 'rejected',
        isEditable: catalogue.status === 'review',
        isDeleteable: catalogue.source === 'SC'
      };
    });
  }, [dataCatalogues]);

  const productCatalogues = useMemo(() => {
    if (!Array.isArray(dataMemoProductCatalogue?.data)) return [];
    return dataMemoProductCatalogue?.data;
  }, [dataMemoProductCatalogue]);

  const lastIdRecordCatalogue = useIdCatalogue().data;
  const lastIdRecord = lastIdRecordCatalogue && lastIdRecordCatalogue.length > 0 ? lastIdRecordCatalogue[0].id + 1 : 1;
  const totalReviewRecords = totalAllCatalogue().data;

  useEffect(() => {
    if (dataProducts) {
      setProduct(dataProducts);
      setFilteredProduct(dataProducts);
    }
  }, [dataProducts]);

  const handleCloseDialog = useCallback(() => {
    const updatedSelection = selection.map((item) => {
      const isValidProduct = rctiProduct.some((prod) => prod.product_id === item.id);
      return { ...item, discount_price: isValidProduct ? item.discount_price : 0 };
    });

    setSelection(updatedSelection);
    setProduct(updatedSelection);
  }, [selection, rctiProduct]);

  useEffect(() => {
    if (dataProducts) {
      const updatedFilteredProduct = product.map((item) => {
        const modifiedProduct = selection.find((prod) => prod.id === item.id);

        return modifiedProduct ?
          { ...item, discount_price: modifiedProduct.discount_price ?? item.discount_price ?? 0 } :
          { ...item, discount_price: 0 };
      });

      if (JSON.stringify(updatedFilteredProduct) !== JSON.stringify(filteredProduct)) {
        setFilteredProduct(updatedFilteredProduct);
      }
    }
  }, [dataProducts, product, selection, filteredProduct]);

  const handleDiscountChange = (value: number, id: number) => {
    const updatedProducts = product.map((item) =>
      item.id === id ? { ...item, discount_price: value } : item
    );
    setProduct(updatedProducts);

    const updatedRCTIProducts = rctiProduct.map((item) => {
      if (item.product_id === id) {
        return { ...item, discount_price: value };
      }
      return item;
    });
    setRctiProduct(updatedRCTIProducts);

    const updatedSelection = selection.map((item) => {
      if (item.id === id) {
        return { ...item, discount_price: value };
      }
      return item;
    });
    setSelection(updatedSelection);

    const updatedProduct = filteredProduct.map((item) => {
      if (item.id === id) {
        return { ...item, discount_price: value };
      }
      return item;
    });
    setFilteredProduct(updatedProduct);
  };

  const handleProductUrlChange = (value: string, id: number) => {
    const updatedRCTIProducts = rctiProduct.map((item) => {
      if (item.product_id === id) {
        return { ...item, product_url: value };
      }
      return item;
    });

    setRctiProduct(updatedRCTIProducts);
  };

  const handleSelectionChange = (e: { value: IListProduct[] }) => {
    const selectedProducts = e.value;
    setSelection(selectedProducts);
  };

  useEffect(() => {
    if (selection.length > 0) {
      const updatedProduct = product.map((prod) => {
        const selectedProduct = selection.find((sel) => sel.id === prod.id);

        if (selectedProduct) {
          return { ...prod, discount_price: selectedProduct.discount_price };
        }

        if (prod.discount_price !== 0) {
          return { ...prod, discount_price: 0 };
        }

        return prod;
      });
      if (JSON.stringify(updatedProduct) !== JSON.stringify(product)) {
        setProduct(updatedProduct);
      }
    }
  }, [selection]);


  const handleClickSubmitAddProduct = useCallback(() => {
    setRctiProduct((prevRctiProducts) => {
      const updatedRctiProducts = prevRctiProducts.filter((prod) =>
        selection.some((sel) => sel.id === prod.product_id)
      );

      const newRctiProducts: RCTIProduct[] = selection
        .filter((sel) => !prevRctiProducts.some((prod) => prod.product_id === sel.id))
        .map((item) => {
          const price = item.product_variants?.price ?? 0;
          const discountPrice = item.discount_price ?? 0;

          const discount = price > 0 ? Math.round(((price - discountPrice) / price) * 100) : 0;

          return {
            catalogue_rcti_id: params.id || lastIdRecord,
            sku: item.sku,
            product_id: item.id,
            product_name: item.name ?? '',
            product_url: '',
            price,
            discount: Math.round(discount * 100) / 100,
            discount_price: discountPrice,
            status: item.is_active,
            image_url: item.images ?? '',
            merchant_name: item.merchants?.name ?? ''
          };
        });

      return [...updatedRctiProducts, ...newRctiProducts];
    });

    setProductPaginator({
      currentProductPage: 1,
      range: {
        start: 0,
        end: perProductPage - 1
      }
    });

    setSelection((prevProducts) => {
      const newProducts = selection.filter((sel) => !prevProducts.some((prod) => prod.id === sel.id));
      return [...prevProducts, ...newProducts];
    });
    setSearchProduct(null);
  }, [selection, perProductPage]);

  useEffect(() => {
    if (params.detailId || params.id) {
      if (status === 'rejected' || (status === 'review')) {
        const hasInvalidStatusNotes = !formInput.status_notes || formInput.status_notes.trim() === '';
        setButtonProductList(!hasInvalidStatusNotes);
      } else if (status === 'approved') {
        if (rctiProduct) {
          const hasInvalidProductUrl = rctiProduct.some((item) => (item.product_url ?? '').trim() === '');
          setButtonProductList(!hasInvalidProductUrl);
        }
      }
    } else {
      if (rctiProduct) {
        const hasInvalidProductUrl = rctiProduct.some((item) => (item.product_url ?? '').trim() === '');
        setButtonProductList(!hasInvalidProductUrl);
      }
    }
  }, [rctiProduct, status, formInput]);

  useEffect(() => {
    if (selection) {
      const hasInvalidDiscount = selection.some((item) => item.discount_price === 0);
      setButtonAddProduct(hasInvalidDiscount);
    }
  }, [selection]);

  const handleSearchProduct = useCallback(
    ({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>) => {
      setSearchProduct(currentTarget.value);

      if (dataProducts) {
        const filtered = dataProducts
          .map((item) => {
            const modifiedProduct = selection.find((prod) => prod.id === item.id);
            return modifiedProduct ?
              { ...item, discount_price: modifiedProduct.discount_price ?? 0 } :
              { ...item, discount_price: item.discount_price ?? 0 };
          })
          .filter((item) =>
            item.name?.toLowerCase().includes(currentTarget.value.toLowerCase())
          );
        setFilteredProduct(filtered);
      }
    },
    [dataProducts, product, searchProduct]
  );

  const handleCreateCatalogue = useCallback( async () => {
    setIsLoading(true);
    const payloadInsert = {
      id: lastIdRecord,
      name: formInput.name,
      descriptions: formInput.descriptions,
      periode: formInput.periode,
      voucher: formInput.voucher,
      url: `${import.meta.env.VITE_APP_SHOP_PLUS_URL}/index.html?catalogue=catalogue${lastIdRecord}`,
      status: 'approved',
      merchant_name: 'Regular',
      created_at: timeNow,
      updated_at: timeNow,
      approved_by: user?.email,
      source: 'BOS',
      is_deleted: false
    };
    const { error: errorProduct } = await supabase.from(TABLE.PRODUCT_RCTI).insert(rctiProduct);
    if (errorProduct) {
      showFailedToast('Failed Product Catalogue');
      setIsLoading(false);
      return;
    }

    const { error } = await supabase.from(TABLE.CATALOGUE_RCTI).insert(payloadInsert);
    if (error) {
      showFailedToast('Failed Create Catalogue');
      setIsLoading(false);
      return;
    }

    const response = await fetch(`${import.meta.env.VITE_APP_SUPABASE_URL}/functions/v1/oss-catalogue-service?catalogue_rcti_id=${lastIdRecord}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const statusCode = response.status;
    if (statusCode === 200) {
      showSuccessToast('Request was successful!');
    } else if (statusCode === 404) {
      showFailedToast('Resource not found!');
    } else {
      showFailedToast('Unexpected status code: ${statusCode}');
    }

    showSuccessToast();
    refecthCatalogue();
  }, [formInput, rctiProduct]);

  const findCatalogueNamesDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase
      .from(TABLE.CATALOGUE_RCTI)
      .select('id, name')
      .ilike('name', `*${findName}*`)
      .limit(10);

    if (data) {
      const concatData = uniqBy([...foundCatalogueNames || [], ...data], 'id');
      setFoundCatalogueNames(concatData);
    }
  }, 250);

  const findCatalogueNames = async (findName: string | null) => {
    findCatalogueNamesDebounce(findName);
  };

  const formInputValue = (key: string, value: boolean | string | number | Date ) => {
    formik.setFieldValue(key, value);
    setFormInput((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      shopPlus: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleChangeDropdownProductPage = useCallback((event: DropdownChangeEvent) => {
    const newLimit = event.value;
    setLimit(newLimit);
    setPerProductPage(newLimit);
    setOffset(0);
    setProductPaginator((prev) => ({
      ...prev,
      currentProductPage: 1,
      range: { start: 0, end: newLimit - 1 }
    }));
  }, []);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickSubmitFilter = useCallback(() => {
    const updatedFilters = {
      ...itemFilters,
      status: 'review',
      keyword: search
    };

    setFilters(updatedFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage, search]);

  const handleClickCloseState = useCallback(() => {
    setSelection((prevProducts) => {
      const newProducts = selection.filter((sel) => !prevProducts.some((prod) => prod.id === sel.id));
      return [...prevProducts, ...newProducts];
    });
    setProductPaginator({
      currentProductPage: 1,
      range: {
        start: 0,
        end: perProductPage - 1
      }
    });
    setSearchProduct(null);
  }, [selection, perProductPage]);

  const storeFilterHistory = useCallback((filter) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status.toUpperCase()));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage, search, itemFilters]);

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickProductNext = useCallback(() => {
    if (productPaginator.currentProductPage < totalProductPages) {
      const newOffset = offset + limit;
      setOffset(newOffset);
      setProductPaginator((prev) => ({
        ...prev,
        currentProductPage: prev.currentProductPage + 1,
        range: {
          start: newOffset,
          end: newOffset + limit - 1
        }
      }));
    }
  }, [productPaginator, totalProductPages, limit, offset]);

  const handleClickProductPrev = useCallback(() => {
    if (productPaginator.currentProductPage > 1) {
      const newOffset = offset - limit;
      setOffset(newOffset);
      setProductPaginator((prev) => ({
        ...prev,
        currentProductPage: prev.currentProductPage - 1,
        range: {
          start: newOffset,
          end: newOffset + limit - 1
        }
      }));
    }
  }, [productPaginator, limit, offset]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleChangeJumpToProductpage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToProductPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleJumpToProductPage = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        const pageValue = Math.max(1, Math.min(jumpToProductPage, totalProductPages)); // Validasi nilai halaman
        const newOffset = (pageValue - 1) * limit;
        const rangeEnd = Math.min(newOffset + limit - 1, totalProductRecords - 1);

        setOffset(newOffset); // Perbarui `offset`
        setProductPaginator(() => ({
          currentProductPage: pageValue,
          range: {
            start: newOffset,
            end: rangeEnd
          }
        }));
      }
    },
    [jumpToProductPage, totalProductPages, limit, totalProductRecords]
  );

  const handleClickPage = useCallback(
    (page: number) => () => {
      setPaginator((prev) => ({
        ...prev,
        currentPage: page,
        range: {
          start: page === 1 ? page - 1 : perPage * page - perPage,
          end: page * perPage - 1
        }
      }));
    },
    [perPage]
  );

  useEffect(() => {
    setVisitedPage({
      ...visitedPage,
      shopPlus: paginator.currentPage,
      productShopPlus: productPaginator.currentProductPage
    });
  }, [paginator.currentPage]);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      shopPlus: JSON.stringify(filters)
    });
  }, [filters]);

  useEffect(() => {
    if (params.id || params.detailId) {
      getDetailPage();
    }
  }, [params]);

  return {
    data: {
      product,
      filterHistory,
      perPage,
      paginator,
      dataCatalogues,
      dataProducts,
      dataProductCatalogues,
      totalReviewRecords,
      totalRecords,
      lastIdRecord,
      totalPages,
      isLoading,
      isLoadingCatalogues,
      isLoadingProducts,
      isLoadingProductCatelogues,
      catalogues,
      productCatalogues,
      search,
      toast,
      formik,
      formInput,
      params,
      selection,
      filteredProduct,
      rctiProduct,
      perProductPage,
      productPaginator,
      totalProductPages,
      totalProductRecords,
      dataMemoProductCatalogue,
      buttonAddProduct,
      buttonProductList,
      editDiscountPrice,
      editProductId,
      status
    },
    method: {
      handleChangeDropdownPage,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleSearch,
      findCatalogueNames,
      formInputValue,
      confirmCopy,
      handleClickNext,
      handleClickPrev,
      handleJumpToPage,
      handleChangeJumpTopage,
      storeFilterHistory,
      handleClickPage,
      detailCatalogue,
      confirmDelete,
      confirmProductDelete,
      handleCreateCatalogue,
      handleEditCatalogue,
      isFormFieldValid,
      getDetailPage,
      resetForm,
      setProduct,
      handleSearchProduct,
      handleClickSubmitFilter,
      handleClickSubmitAddProduct,
      handleSelectionChange,
      handleDiscountChange,
      handleProductUrlChange,
      handleChangeDropdownProductPage,
      handleClickProductNext,
      handleClickProductPrev,
      handleJumpToProductPage,
      handleChangeJumpToProductpage,
      handlePageChange,
      handleLimitChange,
      handleEditClick,
      handleSaveClick,
      handleCloseDialog,
      handleStatusChange,
      handleClickCloseState
    }
  };
};

export default useCustom;
