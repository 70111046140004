import useCustom, { ProductRcti } from './hooks';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useNavigate } from 'react-router-dom';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { usePermissionStore } from '@/store/usePermissions';
import Table from '@/components/base/DataTable/DataTable';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Toast } from 'primereact/toast';
import { IPagination } from '@/components/base/Pagination';
import { RCTIProduct } from './type';

const CreateShopPlus = () => {
  const { data, method } = useCustom();
  const navigate = useNavigate();
  const getFormErrorMessage = (name) => {
    return method.isFormFieldValid(name) && <small className="p-error">{data.formik.errors[name]}</small>;
  };
  const formatRupiahWithoutSymbol = (number: number) => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  };

  const priceProductTemplate = (product: ProductRcti) => {
    const price = product.product_variants.price ?? 0;
    return (
      <div>
        <div className="flex gap-2">
          <span className="ellipsis">{formatRupiahWithoutSymbol(price)}</span>
        </div>
      </div>
    );
  };

  const priceTemplate = (product: RCTIProduct) => {
    const price = product.price ?? 0;
    return <span>{formatRupiahWithoutSymbol(price)}</span>;
  };

  const capitalize = (text: string) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const header = [
    { field: 'sku', header: 'SKU' },
    { field: 'product_name', header: 'Product' },
    { field: 'merchant_name', header: 'Merchant' },
    { field: 'price', header: 'Price', body: priceTemplate }
  ];

  const headerProduct = [
    { field: 'sku', header: 'SKU' },
    { field: 'id', header: 'Product ID' },
    { field: 'name', header: 'Produk Name' },
    { field: 'merchants.name', header: 'Merchant' },
    { field: 'product_variants.price', header: 'Price', body: priceProductTemplate }
  ];
  const { canDelete, canEdit } = usePermissionStore();

  const filterContent = (
    <div className="flex flex-col gap-4">
      <Table
        isWithCheckBox
        onSelectionChange={(e) => {
          const selection = e.value || [];
          if (selection.length !== data.selection.length) {
            method.handleSelectionChange(e);
          }
        }}
        selection={data.selection as []}
        onSearch={method.handleSearchProduct}
        onhandleDiscountChange={method.handleDiscountChange}
        headerTable={headerProduct}
        value={data.filteredProduct as []}
        handleDeleteFilterHistory={method.handleDeleteFilterHistory}
        isCustomAction
        createAdd
        formUtm
        isCustomeInput
      />
    </div>
  );
  const today = new Date();

  const paginationOptions: IPagination = {
    perPage: data.perProductPage,
    totalPages: data.totalProductPages,
    currentPage: data.productPaginator.currentProductPage,
    disabledDropdown: data.totalProductRecords === 0,
    onChangeDropdownPage: method.handleChangeDropdownProductPage,
    onClickNext: method.handleClickProductNext,
    onClickPrev: method.handleClickProductPrev,
    onJumptoPage: method.handleJumpToProductPage,
    onChangeJumptoPage: method.handleChangeJumpToProductpage
  };
  return (
    <BaseContainer>
      <Toast ref={data.toast} />
      <MainTitle
        title={
          (data.params.id && 'Edit Catalogue') || (data.params.detailId && 'Detail Catalogue') || 'Create Catalogue'
        }
      />
      {/* loading screen */}
      <LoadingOverlay show={data.isLoading} />
      <div id="form group">
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="input-catalogue-id" className="w-1/6">
            Catalogue ID
          </label>
          <div className="card w-1/2">
            <InputText
              id="input-catalogue-id"
              className="w-full"
              value={data.params.id || data.params.detailId || String(data.lastIdRecord)}
              placeholder="Catalogue ID"
              disabled
            />
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="input-catalogue-name" className="w-1/6">
            Catalogue Name
          </label>
          <div className="card w-1/2">
            <InputText
              required
              id="input-catalogue-name"
              className="w-full"
              placeholder="Catalogue Name"
              value={data.formInput.name ?? ''}
              onChange={(e) => method.formInputValue('name', e.target.value)}
              disabled={Boolean(data.params.detailId) || Boolean(data.params.id)}
              maxLength={255}
            />
            {getFormErrorMessage('name')}
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="input-description" className="w-1/6">
            Description
          </label>
          <div className="card w-1/2">
            <InputText
              id="input-description"
              className="w-full"
              placeholder="Description"
              value={data.formInput.descriptions ?? ''}
              onChange={(e) => method.formInputValue('descriptions', e.target.value)}
              disabled={Boolean(data.params.detailId) || Boolean(data.params.id)}
            />
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="input-description" className="w-1/6">
            Voucher
          </label>
          <div className="card w-1/2">
            <InputText
              id="input-voucher"
              className="w-full"
              placeholder="Voucher"
              value={data.formInput.voucher ?? ''}
              onChange={(e) => method.formInputValue('voucher', e.target.value)}
              disabled={Boolean(data.params.detailId) || Boolean(data.params.id)}
            />
          </div>
        </div>
        <div className="py-3 flex gap-8 items-center">
          <label htmlFor="show_to" className="w-1/6">
            Periode
          </label>
          <div className="card flex flex-col w-1/2">
            <Calendar
              dateFormat="yy-mm-dd"
              id="periode"
              showIcon
              hideOnDateTimeSelect
              value={data.formInput.periode ?? ''}
              onChange={(e: CalendarChangeEvent) => method.formInputValue('periode', e.value as string)}
              disabled={Boolean(data.params.detailId) || Boolean(data.params.id)}
              minDate={today}
              placeholder="Pilih tanggal"
            />
            {getFormErrorMessage('periode')}
          </div>
        </div>
        { data.formInput.source == 'SC' && (
          <div>
            <div className="py-3 flex gap-8 items-center">
              <label htmlFor="select_platform" className="w-1/6">
                Status
              </label>
              {!data.params.detailId ? (
                <div className="flex gap-8">
                  <div className="card">
                    <Checkbox
                      checked={data.status === 'rejected' || data.status === 'review'} // Hanya centang jika `status` adalah `rejected`
                      onChange={(e: CheckboxChangeEvent) => method.handleStatusChange(e.checked ? 'rejected' : 'rejected')}
                    />
                    <label className="ml-5">Reject</label>
                  </div>
                  <div className="card">
                    <Checkbox
                      checked={data.status === 'approved'} // Hanya centang jika `status` adalah `approved`
                      onChange={(e: CheckboxChangeEvent) => method.handleStatusChange(e.checked ? 'approved' : 'approved')}
                    />
                    <label className="ml-5">Approve</label>
                  </div>
                </div>
              ) : (
                <span
                  style={{
                    fontWeight: 'bold',
                    color:
                      data.status === 'approved' ? '#2E7D32' :
                        data.status === 'rejected' ? '#EF4444' :
                          data.status === 'review' ? '#E6C530' : 'black'
                  }}
                >
                  {capitalize(data.status)}
                </span>
              )}
            </div>
            {data.status !== 'approved' && (data.formInput.status !== 'approved') && (
              <div className="py-3 flex gap-8 items-center">
                <label htmlFor="input-description" className="w-1/6" />
                <div className="card w-1/2">
                  <label htmlFor="input-note" className="w-1/6">Tambah Catatan</label>
                  <InputTextarea
                    id="input-note"
                    className="w-full"
                    placeholder="Catatan"
                    onChange={(e) => method.formInputValue('status_notes', e.target.value)}
                    value={data.formInput.status_notes}
                    disabled={Boolean(data.params.detailId)}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="mt-10">
          <MainTitle
            title="Product List"
          />
          <Table
            searchHide
            headerTable={header}
            value={data.dataMemoProductCatalogue.data as []}
            customId="product_id"
            isCustomShopPlus={data.formInput.source === 'SC' ? false : !data.params.detailId}
            isCustomAction
            isDeleteAction={canDelete}
            isEditAction={!data.params.id && !data.params.detailId ? false : canEdit}
            isDetailAction={false}
            filterContent={filterContent}
            rows={data.totalProductRecords}
            onDeleted={method.confirmProductDelete}
            onEditable={method.handleEditClick}
            onSave={method.handleSaveClick}
            handleDeleteFilterHistory={method.handleDeleteFilterHistory}
            onSubmitFilter={method.handleClickSubmitAddProduct}
            onCloseState={method.handleClickCloseState}
            editableData={data.rctiProduct}
            onhandleProductUrlChange={method.handleProductUrlChange}
            onhandleDiscountChange={method.handleDiscountChange}
            createAdd={data.formInput.source === 'SC' ? false : !data.params.detailId}
            formUtm={data.formInput.status === 'review' ? true : !data.params.detailId}
            editDiscountPrice={data.editDiscountPrice}
            editProductId={data.editProductId}
            paginationOptions={paginationOptions}
            countDiscountPrice={data.selection.length === 0 ? true : data.buttonAddProduct}
            isUTMInput
            isDiscountPriceEdit
          />
        </div>
      </div>
      <div id="buttons-action" className="w-full flex justify-end gap-6">
        {/* <Toast position='center' ref={data.toast} /> */}
        <SubmitButton
          onCancel={() => {
            method.resetForm();
            navigate('/shop-plus');
          }}
          disabledSubmit={data.dataMemoProductCatalogue.count === 0 ? true : !data.buttonProductList}
          onSubmit={data.params.detailId ? () => navigate(`/shop-plus/edit/${data.params.detailId}`) : () => data.formik.handleSubmit()}
          labelBack={data.params.detailId ? 'Kembali' : 'Batal'}
          labelSubmit={data.params.id ? 'Konfirmasi' : data.params.detailId ? '' : 'Simpan'}
        />
      </div>
    </BaseContainer>
  );
};

export default CreateShopPlus;
